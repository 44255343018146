import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Dropdown, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';
const Experience = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (

    <section id="projects">

      <Container>
        <div className="project-wrapper">
          <Title title="Experience" />

          <Row>
            <Col lg={12} sm={12}>
              <Fade
                  left={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={500}
                  distance="30px"
              >
                <div className="project-wrapper__text">
                  <h3 className="project-wrapper__text-title">American Express - Software Engineer Intern</h3>

                  <Dropdown.Divider />

                  <br/>
                  <div>
                    <strong>

                    <p>
                      Loyalty & Rewards Platforms
                    </p>




                    </strong>

                    <p>
                      June, 2020 - August 2020
                    </p>
                    <p>
                      - Integrated a circuit breaker into a high-traffic Java, Kafka, Redis back-end processing 20 million
                      requests per day to alert and reset connected non-responsive backend services and avoid sending bad
                      requests for redundant processing.
                    </p>
                  </div>
                </div>

              </Fade>

            </Col>

          </Row>



          <Row>
            <Col lg={12} sm={12}>
              <Fade
                  left={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={500}
                  distance="30px"
              >
                <div className="project-wrapper__text">
                  <h3 className="project-wrapper__text-title">Triveni IT - Web Developer Intern</h3>

                  <Dropdown.Divider />
                  <br/>
                  <div>
                    <strong>
                      <p>
                        Django & CMS Systems
                      </p>
                    </strong>

                    <p>
                      June, 2017 - August 2018
                    </p>
                    <p>
                      - Created Django based apps for various warehouse management systems
                    </p>
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>


        </div>
      </Container>

      <Dropdown.Divider />
    </section>
  );
};

export default Experience;
