import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import Particles from 'react-particles-js';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">

      <Container>

        <Fade left={isDesktop} bottom={isMobile} duration={200} delay={1200} distance="20px">

          <Particles/>
        </Fade>

        <Fade left={isDesktop} bottom={isMobile} duration={200} delay={500} distance="20px">
          <h1 className="hero-title">
            <span >Piyush Sharma</span>
            <br />
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={800} delay={700} distance="20px">
          <h1 className="hero-title">
            <span className="text-color-main">Full-Stack Engineer</span>
            <br />

          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={200} delay={1200} distance="30px">

          <p className="">
            <span className="cta-btn cta-btn--hero">
              <Link to="projects" smooth duration={4000}>
                {cta || 'Tell me more'}

              </Link>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
