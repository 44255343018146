import React, {useContext, useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {Col, Container, Dropdown, Row, Table} from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';
import axios from "axios";

const Experience = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  let [repos, setRepos]  = useState([])

  const projects = [{
    'title': 'Krust'
  },{
    'title': 'asshole'
  }]




  function localize(UTCDateString){
    var convertdLocalTime = new Date(UTCDateString);

    var hourOffset = convertdLocalTime.getTimezoneOffset() / 60;

    convertdLocalTime.setHours( convertdLocalTime.getHours() + hourOffset );

    return convertdLocalTime.toString();
  }


  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    const getRepos = async () => {
      let res = await axios.get('  https://api.github.com/users/sharmacloud/repos\n');
      try {

        console.log(res.data)

        setRepos(res.data)
      }
      catch (e){
        console.log(e)
      }
    }
    getRepos()
  }, []);



  return (
    <section id="about">

      <Container>
        <Title title="Projects" />

        <Row>
          <Col sm={12}>
            <Fade
                top={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={500}
                distance="30px"
            >

              <Table striped bordered hover >

                <thead>
                <tr>
                  <th>
                    Project</th>
                  <th >
                    Description
                  </th>
                  <th>
                    Date Created
                  </th>
                </tr>
                </thead>
                <tbody>

        {repos.map((project) => {

          const {url, description, id, name, updated_at} = project;
          console.log(project)

          return (

              <Fade
                  top={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={500}
                  distance="30px"
              >
                <tr className='table-header'>

                  <td>
                    <a href={url} target='_blank'>
                      {name}
                    </a>
                  </td>


                  <td> {description} </td>
                  <td>

                    {localize(updated_at)}</td>

                </tr>
              </Fade>

          );

        })}

                </tbody>


              </Table>

            </Fade>



          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Fade
                top={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={500}
                distance="30px"
            >

            <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn--hero"
                href='http://download1642.mediafire.com/35pza0xtdq0g/qiu65rarmjw4lhu/Piyush-Sharma-Resume.pdf'
            >
              Download Resume
            </a>
            </Fade>
          </Col>
        </Row>
      </Container>
      <Dropdown.Divider />

    </section>
  );
};

export default Experience;
